var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"iFn4f8nVWAcKYjheIE4mu"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { isDev } from './src/lib/runtime'

Sentry.init({
  enabled: !isDev,
  dsn: 'https://602453aaedc64f43bc35bc4b0a577bc7@o4504622046904320.ingest.sentry.io/4504622061125632',
  tracesSampleRate: 1.0,
})
