import '../styles/globals.scss'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import commonEnTranslation from '../assets/locales/en/common.json'
import { isDev } from '../lib/runtime'
import { ErrorBoundary } from '@sentry/nextjs'
import AppCrashFallback from '../components/AppCrashFallback'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import NavBar from 'src/components/NavBar'
import 'core-js/stable/structured-clone'
import { Inter } from '@next/font/google'
import { useAnalytics } from 'src/lib/analytics/useAnalytics'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      common: commonEnTranslation,
    },
  },
  lng: 'en',
  ns: ['common'],
  debug: isDev,
  returnNull: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

const inter = Inter({ subsets: ['latin'] })

const App = ({ Component, pageProps }: AppProps) => {
  useAnalytics()
  return (
    <>
      <Head>
        <title>socialprofiler</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
      </Head>
      <ErrorBoundary fallback={(errorData) => <AppCrashFallback {...errorData} />}>
        <ToastContainer position="top-center" transition={Slide} closeOnClick={false} />
        <main className={inter.className}>
          <NavBar />
          <Component {...pageProps} />
        </main>
      </ErrorBoundary>
    </>
  )
}

// noinspection JSUnusedGlobalSymbols
export default App
