import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import logo from 'src/assets/images/logo.svg'
import constants from 'src/styles/constants.module.scss'

const NavBar = () => {
  const { t } = useTranslation()
  const [menuActive, setMenuActive] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    setIsMobile(document.documentElement.clientWidth < +constants.mobileWidth.split('px')[0] + 1)
  }, [])
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" href="/">
          <Image
            src={logo}
            alt={t('logoText')}
            style={{ maxHeight: 'unset' }}
            width={isMobile ? 236 : 260}
            height={isMobile ? 39 : 43}
            priority={true}
          />
          {/*<span>
            <strong>{t('logoText')}</strong>
           </span>*/}
        </Link>

        <div
          role="button"
          className={`navbar-burger navbar-end ${menuActive ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => setMenuActive(!menuActive)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      <div id="navbarBasicExample" className={`navbar-menu ${menuActive ? 'is-active' : ''}`}>
        <div className="navbar-start">
          <div className="navbar-item">{t('navbarLogin')}</div>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
